import styled from '@emotion/styled'

export const Wrapper = styled.div`
	display: flex;
	position: absolute;
	top: 186px;
    right: 10px;
	width: 120px;
	height: 106px;
	cursor: pointer;
`;